import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { AlertController } from '@ionic/angular';
import { type } from 'os';
import { throwIfEmpty } from 'rxjs/operators';
import { LoggingService } from '../logging/logging.service';

import { MediaService } from '../media/media.service';
import { PostService } from '../post/post.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
    providedIn: 'root'
})
export class DraftsService {
    unsavedPosts: any = []
    unsavedFileNames: any = []
    activePostInPublish: any = ""
    constructor(
        public alertController: AlertController,
        public mediaService: MediaService,
        public PostService: PostService,
        public storageService: StorageService,
        public loggingService: LoggingService
    ) { }


    async getListOfFilesFromDraftsDir() {
        // console.log("function executed");
        await Filesystem.readdir({
            path: 'drafts',
            directory: Directory.Data
        }).then(async (res) => {
            console.log("show file system response", res);
            if (res == undefined || res.files == undefined) {
                throw new Error("missing dir read data")
            }
            // if (res.files.length <= 0) {
            //     throw new Error("no files in the dir")
            // }
            this.unsavedFileNames = res.files
            this.unsavedPosts = [];

            for (const fileName of this.unsavedFileNames) {
                await Filesystem.readFile({
                    path: 'drafts/' + fileName.name,
                    directory: Directory.Data
                }).then((fileReadRes) => {
                    console.log("here is the file read res", fileReadRes.data, typeof (fileReadRes.data));
                    if (fileReadRes == undefined || fileReadRes.data == undefined) {
                        throw new Error("missing fileRead data")
                    }
                    this.unsavedPosts.push(fileReadRes.data) //send to local
                    console.log("here the array of unsavedposts", this.unsavedPosts);
                }).then(() => {
                    for (const post of this.unsavedPosts) {
                        post.dirPath = 'drafts/' + fileName.name
                        for (const media of post.photos) {
                            media.offlineAddressNew = this.generateURL(media.dataToBeUploaded);
                        }
                    }
                }).catch((readFileError) => {
                    console.log("inside read file error block", readFileError);

                })
            }

        }).catch((redDirError) => {
            console.log("inside read dir error block", redDirError);

        })
    }


    async readTempDirAndGetFilesList(filePath) {
        return new Promise(async (resolve, reject) => {
            await Filesystem.readdir({
                path: filePath,
                directory: Directory.Data
            }).then((res) => {
                //  console.log("api read dir res", res);
                if (!res || res == undefined) return false
                resolve(res)
            }).catch((err) => {
                // log error here
                return false
            })
        })

    }

    async readFileFromGivenPath(filename) {
        return new Promise(async (resolve, reject) => {
            await Filesystem.readFile({
                path: filename,
                directory: Directory.Data,
                encoding: Encoding.UTF8,
            }).then((res: any) => {
                console.log("eraw read file response", res);
                if (!res || res == undefined) return false
                //  console.log("read file response",JSON.parse(res.data) );
                resolve(res)
            }).catch((err) => {
                // log error here
                console.log("read file error", err);

                return false
            })
        })

    }
    /**
     * 
     * @returns true or false based on the execution
     * 1. get all the filenames from the drafts directory and store it in unsavedFilenamesArray
     * 2. stage-one :  first then block: Run a for loop ion the unsavedfilenames array and start reading post by passing file name
     *                 after the post is read, json parse the post and push it in the unsavedposts array. Also, add the post temp path 
     *                 in the post object for delete funcionality in the drafts page
     * 2. stage-two : second then block : start a for loop on the unsavedposts array now and for every post check if there is media present, if yes run a for loop
     *                on every media name and generate blob and display urla nd update the media object of the post
     * 
     * 3. check for the loop completion by evaluting current index with array length and reslove true
     */
    prepareFilesForDispalyAndUploadFromDrafts() {
        return new Promise((resolve, reject) => {
            this.unsavedPosts = [];
            let filePath = 'drafts/' + this.storageService.getStorage('currentUserId')
            this.readTempDirAndGetFilesList(filePath)

                .then(async (res: any) => {
                    //  console.log("response file dir", res);
                    if (!res || res == undefined) throw new Error("false or file is undefined")
                    this.unsavedFileNames = res.files
                    console.log("🚀 ~ file: drafts.service.ts:135 ~ .then ~ this.unsavedFileNames:", this.unsavedFileNames)

                    for (const filename of this.unsavedFileNames) {

                        await this.readFileFromGivenPath(filePath + '/' + filename.name).then((fileReadRes: any) => {
                            if (fileReadRes == undefined || fileReadRes.data == undefined) {
                                throw new Error("missing fileRead data")
                            }
                            console.log("before json parse from temp");
                            try {
                                let parsedPost = JSON.parse(fileReadRes.data)
                                parsedPost.dirPath = filePath + '/' + filename.name
                                // let postObjectForDisplayArray = {
                                //     filename: parsedPost
                                // }

                                this.unsavedPosts.push(parsedPost)
                            } catch (error) {
                                console.log("error while parsing the post", error);

                            }
                            //send to local
                            console.log("after json parse from temp");
                            // this.unsavedPosts.push(fileReadRes.data)
                        })
                    }
                })
                .then(async () => {
                    console.log("second then block of read flie method");
                    for (const post of this.unsavedPosts) {
                        console.log("inside for loop and post object after read file in json");
                        if (post.photos.length < 1) resolve(true)
                        for (const [index, media] of post.photos.entries()) {
                            console.log("inside media for loop");
                            // media.offlineAddressNew = this.generateURL(media.dataToBeUploaded);
                            let blobType
                            switch (media.type) {
                                case 'image':
                                    blobType = 'image/jpg'
                                    break;
                                case 'video':
                                    blobType = 'video/mp4'
                                    break;
                                case 'audio':
                                    blobType = 'audio/mp3'
                                    break;
                                case 'pdf':
                                    blobType = 'application/pdf'
                                    break;

                            }
                            console.log("blobtype before generate", blobType);
                            media.offlineAddressNew = (await this.generateBlobAndDisplayUrl(media.dataToBeUploaded, blobType).then(res => res)).displayUrl;
                            media.dataToBeUploaded = (await this.generateBlobAndDisplayUrl(media.dataToBeUploaded, blobType).then(res => res)).uploadBlob;

                            setTimeout(() => {
                                if (index == post.photos.length - 1) {
                                    resolve(true)
                                }
                            }, 1000);
                        }
                    }
                }).catch((readFileError) => {
                    console.log("inside read file error block", readFileError);
                    reject(false)

                })





        })


    }


    // /**
    //  * 
    //  * @returns true or false 
    //  * Multi stage code with multiple then blocks.
    //  * Every "then" stage accomplishes following steps in synchronous manner
    //  * 1. read all the file names from the drafts directory
    //  * 2. start a for loop and start reading each file based on the given name
    //  *      stage 1 : then-one : json parse the post object and push it in unsavePost array 
    //  *      stage 2 : then-two : if post has media in it, create blob and display url and update the post media object
    //  *      stage 3 : then-three : start media upload to s3
    //  *      stage 4 : then-four : start post upload to db
    //  *      stage 5 : then-five : delete post from the temp dir and array
    //  * 3. resolve promise once for loop is executed
    //  * 4. Catch Block : log error to sentry and reject false.
    //  */
    // HL-681 => for solving duplicate upload bug , created one array object "inProgressPost",
    // inProgressPost => will include unsaved post which is in uploading process (with media and without media also) , 
    // if post is in progress skip duplicate call 
    // and display aler success after all draft 

    inProgressPost: any = [];
    alertDisplay: number = 0;
    async autoPublishDraftPosts() {


        return new Promise((resolve, reject) => {
            this.unsavedPosts = [];
            let filePath = 'drafts/' + this.storageService.getStorage('currentUserId')
            this.readTempDirAndGetFilesList(filePath).then((res: any) => {
                //  console.log("response file dir", res);
                if (!res || res == undefined) throw new Error("false or file is undefined")
                this.unsavedFileNames = res.files
                for (const [index, filename] of this.unsavedFileNames.entries()) {

                    let activePost: any;
                    if (this.inProgressPost.includes(filename.name)) {
                        console.log("Offline testing -------------------ignore file for avoiding duplicating ")
                    } else {
                        this.inProgressPost.push(filename.name);
                        this.readFileFromGivenPath(filePath + '/' + filename.name)
                            //then-one : json parse the post object and push it in unsavePost array 
                            .then((fileReadRes: any) => {
                                if (fileReadRes == undefined || fileReadRes.data == undefined) {
                                    throw new Error("missing fileRead data")
                                }
                                console.log("before json parse from temp");
                                try {
                                    this.activePostInPublish = filePath + '/' + filename.name // use to track the post that is in progress
                                    activePost = JSON.parse(fileReadRes.data)
                                    activePost.dirPath = filePath + '/' + filename.name

                                } catch (error) {
                                    console.log("error while parsing the post", error);
                                    throw new Error(error)
                                }
                                //send to local
                                console.log("after json parse from temp");
                                // this.unsavedPosts.push(fileReadRes.data)
                            })
                            //then-two : if post has media in it, create blob and display url and update the post media object
                            .then(async () => {
                                console.log("second then block of read flie method");

                                if (activePost.photos.length > 0) {
                                    for (const [index, media] of activePost.photos.entries()) {
                                        console.log("inside media for loop");
                                        let blobType
                                        switch (media.type) {
                                            case 'image':
                                                blobType = 'image/jpg'
                                                break;
                                            case 'video':
                                                blobType = 'video/mp4'
                                                break;
                                            case 'audio':
                                                blobType = 'audio/mp3'
                                                break;
                                            case 'pdf':
                                                blobType = 'application/pdf'
                                                break;
                                        }
                                        //  media.offlineAddressNew = (await this.generateBlobAndDisplayUrl(media.dataToBeUploaded, blobType).then(res => res)).displayUrl;
                                        media.dataToBeUploaded = (await this.generateBlobAndDisplayUrl(media.dataToBeUploaded, blobType).then(res => res)).uploadBlob;
                                    }

                                }
                            })
                            //then-three : start media upload to s3
                            .then(async () => {
                                if (activePost.photos.length > 0) {
                                    console.log("stage 3 post media to s3");

                                    activePost.photos = await this.mediaService.processUploadMediaToS3FromOutbox(activePost.photos).then(res => res).catch(err => {
                                        console.log("error in stage 3 upload media to s3", err);
                                        throw new Error(err)
                                    })
                                }
                                else {
                                    return
                                }
                            })
                            //then-four : start post upload to db
                            .then(() => {
                                console.log("stage 4 post save to db");
                                this.PostService.addPost(activePost).subscribe((res) => {
                                    console.log("post saved to db succeeded", res);
                                }, (postSaveToDbError) => {
                                    console.log("postSaveToDbError", postSaveToDbError);
                                    throw new Error(postSaveToDbError)
                                })
                            })
                            //then-five : delete post from the temp dir and array
                            .then(() => {
                                console.log("stage 5 remove post");
                                let deleteRes = this.removePostFromDraft(activePost.dirPath).then(res => res)
                                deleteRes.catch((err) => {
                                    throw new Error(err)
                                })
                            })
                            .catch((autoPublishError) => {
                                this.activePostInPublish = ""
                                console.log("inside auto publish error", autoPublishError);
                                reject(false)

                            })

                    }
                    if (index == this.unsavedFileNames.length - 1) {
                        if (this.alertDisplay == 0) {
                            this.postUPloadalert();
                            this.alertDisplay++;
                        }
                        resolve(true)
                        this.activePostInPublish = ""
                    }
                }
            }).catch((err) => {
                console.log("error", err);
                this.activePostInPublish = ""
                reject(false)
            })
        })
    }



    async generateBlobAndDisplayUrl(stringValue, blobType) {

        try {

            //  let blobValue :any  = await this.mediaService.processAttachedImageForDisplayAndUpoad(stringValue).then(res=>res).catch((err)=>{console.log("here is the error in making image blob", err);
            // })

            console.log("blob string value param");
            let binaryData = Buffer.from(stringValue)
            console.log("binary data ");
            console.log("blob type in generate", blobType);

            const blob = await new Blob([binaryData], { type: blobType })
            //   console.log("here is the converted blob",URL.createObjectURL(blob));
            let returnObject = { displayUrl: URL.createObjectURL(blob), uploadBlob: blob }
            return returnObject
        } catch (error) {
            console.log("something went wrong in blob method", error);

        }


    }
    generateURL(blob) {
        return URL.createObjectURL(blob);
    }

    async removePostFromDraft(fileName) {
        console.log("filename param in delete method", fileName);
        await Filesystem.deleteFile({
            path: fileName,
            directory: Directory.Data,
        }).then(() => {
            console.log(`post removed dir:${fileName}`);
            return true
            // alert(`post uploaded successfully and removed removed dir:${fileName}`)
            // this.getListOfFilesFromDraftsDir(); // refresh list of post after removing post successfully
        }).catch((err) => {
            console.log("err -> ", err);
            // this.presentDraftsPostAlert('Post no longer exists in Outbox.');
            return false
        });

    }


    async postUPloadalert() {
        const alert = await this.alertController.create({
            message: "All saved post uploaded successfully.",
            buttons: [
                {
                    text: 'OK',
                    role: 'confirm',
                    handler: () => {
                        console.log('Alert confirmed');
                        setTimeout(() => {
                            this.alertDisplay = 0;
                        }, 1000);
                    },
                },
            ],
        });

        await alert.present();

    }




    // async uplaodToS3andSavePostToDb(unsavedPost) {
    //     console.log("inside unsaved post");

    //     return new Promise(async (resolve, reject) => {
    //         this.mediaService.processUploadMediaToS3FromOutbox(unsavedPost.photos).then((res: any) => {
    //             //    console.log("media upload of offline in then", res);

    //             unsavedPost.photos = []
    //             unsavedPost.photos = res

    //             console.log("final post object before the upload");
    //             this.PostService.addPost(unsavedPost).subscribe((res) => {
    //                 console.log("post saved to db succeeded", res);

    //                 resolve(true)
    //             }, (postSaveToDbError) => {
    //                 console.log("postSaveToDbError", postSaveToDbError);
    //                 resolve(false)
    //             })
    //         }).catch((mediaUploadError) => {
    //             console.log("media upload error", mediaUploadError);
    //             this.loggingService.logMessage(this.loggingService.loggingConstraints.log_error, 'DraftsService', 'uplaodToS3andSavePostToDb() > mediaService.processUploadMediaToS3FromOutbox() > catch', mediaUploadError);
    //             reject(false)
    //         })




    //     })
    // }


    // /**
    //  * 
    //  */
    // prepareFilesForDispalyAndUploadFromDrafts() {
    //     return new Promise((resolve, reject) => {
    //         this.unsavedPosts = [];
    //         let filePath = 'drafts/' + this.storageService.getStorage('currentUserId')
    //         this.readTempDirAndGetFilesList(filePath)

    //         .then((res: any) => {
    //             //  console.log("response file dir", res);
    //             if (!res || res == undefined) throw new Error("false or file is undefined")
    //             this.unsavedFileNames = res.files

    //             for (const filename of this.unsavedFileNames) {

    //                 this.readFileFromGivenPath(filePath + '/', filename).then((fileReadRes: any) => {
    //                     if (fileReadRes == undefined || fileReadRes.data == undefined) {
    //                         throw new Error("missing fileRead data")
    //                     }
    //                     console.log("before json parse from temp");
    //                     try {
    //                         let parsedPost = JSON.parse(fileReadRes.data)
    //                         let postObjectForDisplayArray = {
    //                             filename : parsedPost
    //                         }

    //                         this.unsavedPosts.push(postObjectForDisplayArray)
    //                     } catch (error) {
    //                         console.log("error while parsing the post", error);

    //                     }
    //                     //send to local
    //                     console.log("after json parse from temp");
    //                     // this.unsavedPosts.push(fileReadRes.data)
    //                 })
    //                 .then(async () => {
    //                     console.log("second then block of read flie method");

    //                     for (const post of this.unsavedPosts) {
    //                         console.log("inside for loop and post object after read file in json");

    //                         post.dirPath = filePath + '/' + filename

    //                         if(post.photos.length < 1) resolve(true)


    //                          for (const [index, media] of post.photos) {
    //                             console.log("inside media for loop");

    //                             // media.offlineAddressNew = this.generateURL(media.dataToBeUploaded);
    //                             let blobType
    //                             switch (media.type) {
    //                                 case 'image':
    //                                     blobType = 'image/jpg'
    //                                     break;
    //                                 case 'video':
    //                                     blobType = 'video/mp4'
    //                                     break;
    //                                 case 'audio':
    //                                     blobType = 'audio/mp3'
    //                                     break;
    //                                 case 'pdf':
    //                                     blobType = 'application/pdf'
    //                                     break;

    //                             }
    //                             console.log("blobtype before generate", blobType);

    //                             media.offlineAddressNew = (await this.generateBlobAndDisplayUrl(media.dataToBeUploaded, blobType).then(res => res)).displayUrl;
    //                             media.dataToBeUploaded = (await this.generateBlobAndDisplayUrl(media.dataToBeUploaded, blobType).then(res => res)).uploadBlob;

    //                             setTimeout(() => {
    //                                 if(index == post.photos.length -1){
    //                                     resolve(true)
    //                                 }
    //                             }, 1000);
    //                         }
    //                     }


    //                 }).catch((readFileError) => {
    //                     console.log("inside read file error block", readFileError);
    //                     reject(false)

    //                 })




    //         }).catch((err) => {
    //             console.log("error", err);
    //             reject(false)
    //         })
    //     })


    // }






    // async autoSavePostsFromOutboxInBackground() {
    //     /**
    //      * 1. check if files exist in temp dir
    //      * 2. if true , get all the files and start upload process
    //      * 3. in the upload process, check for publish status flag of the post . If "not started" then process the upload and mark the publish status to "in process"
    //      * 4. upload media to s3
    //      * 5. save post to db
    //      * 6. delete the file from the dir
    //      * 
    //      * 
    //      */
    //     console.log("auto publish called ");

    //     let filePath = 'drafts/' + this.storageService.getStorage('currentUserId')
    //     const isFilesInDir = await this.readTempDirAndGetFilesList(filePath).then((res: any) => { return res.files.length > 0 ? true : false }).catch((err) => { return false })
    //     console.log("is files in dir", isFilesInDir);

    //     if (isFilesInDir == true) {
    //         this.prepareFilesForDispalyAndUploadFromDrafts().then((res) => {
    //             console.log("prepare files for display", res);
    //             if (!res || res == undefined) {
    //                 return false
    //             }


    //             for (const post of this.unsavedPosts) {
    //                 if (post.offlinePublishStatus == "in process") continue // skip this iteration in loop

    //                 post.offlinePublishStatus = "in process"
    //                 this.uplaodToS3andSavePostToDb(post).then((res) => {
    //                     console.log("post uploaded successfully");
    //                     this.removePostFromDraft(post.dirPath).then((res)=>res)
    //                 }).catch((err) => {
    //                     console.log("post upload error ", err);
    //                     this.loggingService.logMessage(this.loggingService.loggingConstraints.log_error, 'DraftsService', 'autoSavePostsFromOutboxInBackground() > uplaodToS3andSavePostToDb() > catch', err);
    //                     post.offlinePublishStatus = "not started"
    //                 })
    //             }

    //         }).catch((err) => {
    //             console.log("error in auto publish", err);

    //         })
    //     }
    //     else { // no files in drafts dir
    //         return
    //     }

    // }





}
